import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

import { LiteOrderExtraItemSchema } from "./LiteOrderExtraItemSchema";
import { nonEmptyString, optionalNonEmptyString } from "./common";

export const BasketExtraItemFormSchema = LiteOrderExtraItemSchema.extend({
  id: nonEmptyString(),
  quantity: z.preprocess(
    preprocessFormAmountValue,
    z
      .number({ required_error: "Quantity is required" })
      .positive({ message: "Quantity must be greater than 0" })
      .nullable()
  ),
  name: nonEmptyString({ requiredMessage: "Product name is required" }),
  measurementUnit: nonEmptyString({ requiredMessage: "Unit of measure is required" }),
  refProductCode: optionalNonEmptyString({
    requiredMessage: "Ref code, if provided, must not be empty",
  }),
  refUrl: optionalNonEmptyString({
    requiredMessage: "Reference link, if provided, must not be empty",
  }),
});

export type LocalBasketExtraItemForm = z.infer<typeof BasketExtraItemFormSchema>;
