import { DefaultError, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { createOrder } from "src/api/createOrder";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { ordersStore } from "src/objectStorage";
import { LiteOrderCreationRequest, LiteOrderRequisition } from "src/typegens";
import { deleteDraftFromOrderRequisitionsList, prependToOrderRequisitionsList } from "src/utils";

import { useDeleteOrderDraftMutation } from "./orderDrafts/useDeleteOrderDraftMutation";
import useBasket from "./useBasket";
import { useToastMessage } from "./useToastMessage";

export const useCreateOrderMutation = (draftId?: string) => {
  const navigate = useNavigate();
  const { clearOrderRequisitionFlow } = useBasket();
  const [, dispatch] = useAppStateContext();
  const { setToastMessage } = useToastMessage();
  const queryClient = useQueryClient();

  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();

  const { mutate: deleteDraft } = useDeleteOrderDraftMutation({
    hasErrorMessage: true,
  });

  const handleDraftRemoval = async () => {
    if (!draftId) {
      return;
    }

    if (isOfflineDraftEnabled) {
      return deleteDraft(draftId);
    }

    queryClient.setQueryData(
      // Drafts are not closed orders, so this simplification is enough
      ["orderRequisitionsList", false],
      deleteDraftFromOrderRequisitionsList(draftId)
    );
  };

  return useMutation<LiteOrderRequisition, DefaultError, LiteOrderCreationRequest>({
    mutationKey: ["createOrder"],
    mutationFn: createOrder,
    onSuccess: async (result) => {
      // Cache manipulation
      await handleDraftRemoval();

      queryClient.setQueryData(
        // New orders are not closed, so this simplification is enough
        ["orderRequisitionsList", false],
        prependToOrderRequisitionsList([result])
      );

      if (areOfflineCapabilitiesEnabled) {
        await ordersStore.put(result, result.id);
      }

      // State updates
      dispatch({
        type: "setLastCreated",
        value: [result],
      });
      clearOrderRequisitionFlow();

      // Navigate to the main route
      navigate(RoutesConfig.order.success);
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "There was an error when creating an order",
      });
    },
  });
};
