export const LocalStocktakeTypes = [
  "BONDED",
  "PROVISIONS",
  "STORES",
  "WELFARE",
  "GALLEY_CONSUMABLES",
  "GALLEY_AND_LAUNDRY_EQUIPMENT",
  "DEFAULT",
] as const;
export type LocalStocktakeType = typeof LocalStocktakeTypes[number];

export type LocalStocktakeTypeConfiguration = {
  type: LocalStocktakeType;
  name: string;
};
