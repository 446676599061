import { useCallback, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  DeleteStocktakeDraftModal,
  DeleteStocktakeReportModal,
  LocalStocktakeType,
} from "@web/common";
import { Modal } from "@web/ui";

import { RoutesConfig, RoutesParams } from "src/config/routes";
import {
  SortStocktakesListBy,
  SortStocktakesListDir,
  useDeleteStocktakeDraftMutation,
  useDeleteStocktakeReportMutation,
  useSortedStocktakesListQuery,
} from "src/hooks/stocktakes";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { StocktakesTableUI } from "./StocktakesTableUI";

type Props = {
  vesselId: string | undefined;
  availableStocktakeTypes?: LocalStocktakeType[];
};

export const StocktakesTableController = ({ vesselId, availableStocktakeTypes }: Props) => {
  const [sortDirValue, setSortDirValue] = useState<SortStocktakesListDir>("DESC");
  const [sortByValue, setSortByValue] = useState<SortStocktakesListBy>("STATUS");

  const stocktakesQuery = useSortedStocktakesListQuery({
    vesselId: vesselId || "",
    sortBy: sortByValue,
    sortDir: sortDirValue,
  });

  const navigate = useNavigate();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();

  const stocktakeListItems = useMemo(() => stocktakesQuery.data || [], [stocktakesQuery.data]);

  // Delete draft
  // ---------------------------------------------------------------------------

  const [isDeleteStocktakeDraftModalOpen, setIsDeleteStocktakeDraftModalOpen] =
    useState<boolean>(false);
  const [draftIdToDelete, setDraftIdToDelete] = useState<string | undefined>();

  const openDeleteStocktakeDraftModal = useCallback((draftId: string) => {
    setDraftIdToDelete(draftId);
    setIsDeleteStocktakeDraftModalOpen(true);
  }, []);
  const closeDeleteStocktakeDraftModal = useCallback(() => {
    setIsDeleteStocktakeDraftModalOpen(false);
    setDraftIdToDelete(undefined);
  }, []);

  const { mutate: deleteStocktakeDraft, isPending: isDeletingStocktakeDraft } =
    useDeleteStocktakeDraftMutation({
      hasSuccessMessage: true,
      hasErrorMessage: true,
      onSuccess: () => {
        closeDeleteStocktakeDraftModal();
      },
      vesselId: vesselId || "",
      draftId: draftIdToDelete || "",
    });

  const onDeleteStocktakeDraftConfirm = useCallback(() => {
    if (!draftIdToDelete || isDeletingStocktakeDraft) {
      return;
    }
    deleteStocktakeDraft();
  }, [deleteStocktakeDraft, draftIdToDelete, isDeletingStocktakeDraft]);

  // Delete report
  // ---------------------------------------------------------------------------

  const [isDeleteStocktakeReportModalOpen, setIsDeleteStocktakeReportModalOpen] =
    useState<boolean>(false);
  const [reportIdToDelete, setReportIdToDelete] = useState<string | undefined>();

  const openDeleteStocktakeReportModal = useCallback((reportId: string) => {
    setReportIdToDelete(reportId);
    setIsDeleteStocktakeReportModalOpen(true);
  }, []);
  const closeDeleteStocktakeReportModal = useCallback(() => {
    setIsDeleteStocktakeReportModalOpen(false);
    setReportIdToDelete(undefined);
  }, []);

  const { mutate: deleteStocktakeReport, isPending: isDeletingStocktakeReport } =
    useDeleteStocktakeReportMutation({
      hasSuccessMessage: true,
      hasErrorMessage: true,
      onSuccess: () => {
        closeDeleteStocktakeReportModal();
      },
      vesselId: vesselId || "",
      stocktakeId: reportIdToDelete || "",
    });

  const onDeleteStocktakeReportConfirm = useCallback(() => {
    if (!reportIdToDelete || isDeletingStocktakeReport) {
      return;
    }

    allowOnlineOnly(() => {
      deleteStocktakeReport();
    });
  }, [reportIdToDelete, isDeletingStocktakeReport, allowOnlineOnly, deleteStocktakeReport]);

  const handleDeleteStocktakeReport = useCallback(
    (reportIdToDelete: string) => {
      allowOnlineOnly(() => {
        openDeleteStocktakeReportModal(reportIdToDelete);
      });
    },
    [allowOnlineOnly, openDeleteStocktakeReportModal]
  );

  // ---------------------------------------------------------------------------

  const navigateToStocktakeDetails = useCallback(
    (stocktakeId: string) => {
      navigate(
        generatePath(RoutesConfig.stocktake.details, {
          [RoutesParams.STOCKTAKE_ID]: stocktakeId,
        })
      );
    },
    [navigate]
  );

  const navigateToStocktakeEdit = useCallback(
    (stocktakeId: string) => {
      navigate(
        generatePath(RoutesConfig.stocktake.edit, {
          [RoutesParams.STOCKTAKE_ID]: stocktakeId,
        })
      );
    },
    [navigate]
  );

  const canRenderStocktakeTypes =
    LocalStocktakeService.shouldRenderStocktakeTypes(availableStocktakeTypes);
  const canRenderStocktakeType = useCallback(
    (stocktakeType: LocalStocktakeType) =>
      LocalStocktakeService.shouldRenderStocktakeType(availableStocktakeTypes, stocktakeType),
    [availableStocktakeTypes]
  );

  return (
    <div className="flex flex-col items-center mt-3" data-testid="stocktakesOverview_table">
      <AllowOnlineOnlyWarningModal />
      <Modal isOpen={isDeleteStocktakeDraftModalOpen} closeModal={closeDeleteStocktakeDraftModal}>
        <DeleteStocktakeDraftModal
          closeModal={closeDeleteStocktakeDraftModal}
          isLoading={isDeletingStocktakeDraft}
          onConfirm={onDeleteStocktakeDraftConfirm}
        />
      </Modal>
      <Modal isOpen={isDeleteStocktakeReportModalOpen} closeModal={closeDeleteStocktakeReportModal}>
        <DeleteStocktakeReportModal
          closeModal={closeDeleteStocktakeReportModal}
          isLoading={isDeletingStocktakeReport}
          onConfirm={onDeleteStocktakeReportConfirm}
        />
      </Modal>
      <StocktakesTableUI
        stocktakes={stocktakeListItems}
        shouldRenderStocktakeTypes={canRenderStocktakeTypes}
        shouldRenderStocktakeType={canRenderStocktakeType}
        onDeleteDraft={openDeleteStocktakeDraftModal}
        onResumeDraft={navigateToStocktakeEdit}
        onViewDetails={navigateToStocktakeDetails}
        onDeleteReport={handleDeleteStocktakeReport}
        onSetSortDirValue={setSortDirValue}
        onSetSortByValue={setSortByValue}
        isLoading={stocktakesQuery.isPending || stocktakesQuery.isFetching}
      />
    </div>
  );
};
