import * as Sentry from "@sentry/browser";
import { Component, PropsWithChildren } from "react";
import { NavigateFunction } from "react-router-dom";

import {
  EmptyCatalogErrorPage,
  FallbackErrorPage,
  ForbiddenErrorPage,
  NotFoundErrorPage,
  ServerErrorPage,
  UnauthorizedErrorPage,
} from "@web/ui";
import { isParsingError, isStorageError } from "@web/utils";

import { EMPTY_CATALOG_ERROR_CODE } from "src/config/constants";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { DebuggerMenuController } from "src/domain";
import { isApiError, isOfflineCatalogError } from "src/utils";

const NetworkRelatedError = ({
  status,
  navigate,
}: {
  status: number;
  navigate: NavigateFunction;
}) => {
  const [{ isPunchoutSession }] = useAppStateContext();

  if (status >= 500) {
    return <ServerErrorPage errorCode={status} />;
  }

  if (status === 401) {
    return <UnauthorizedErrorPage />;
  }

  if (status === 403) {
    return <ForbiddenErrorPage />;
  }

  if (status === 404) {
    return (
      <NotFoundErrorPage
        onButtonClick={() =>
          navigate(isPunchoutSession ? RoutesConfig.gatherSetup : RoutesConfig.mainPage)
        }
        buttonText={isPunchoutSession ? "Go To Port Selector" : "Go To Overview"}
      />
    );
  }

  // Fallback for unknown API errors
  return <FallbackErrorPage errorDescription={`${status}`} />;
};

type Props = {
  navigate: NavigateFunction;
  pathname: string;
};

type State = {
  hasError: boolean;
  error: unknown;
};

export class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  state: State = { hasError: false, error: null };

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error };
  }

  componentDidCatch(error: unknown) {
    Sentry.captureException(error);
  }

  componentDidUpdate(prevProps: Readonly<PropsWithChildren<Props>>) {
    if (prevProps.pathname !== this.props.pathname) {
      // Reset state whenever user navigates to other route
      this.setState({ hasError: false, error: null });
    }
  }

  render() {
    const error = this.state.error;
    const { navigate } = this.props;

    if (error) {
      console.error(error);
    }

    if (isApiError(error) || isStorageError(error)) {
      return (
        <>
          <NetworkRelatedError status={error.status} navigate={navigate} />
          <DebuggerMenuController error={error} errorDescription={error.message} />
        </>
      );
    }

    if (isParsingError(error)) {
      // We are handling parsing errors here
      return (
        <>
          <FallbackErrorPage errorDescription="Data Parsing Error" error={error} />
          <DebuggerMenuController error={error} errorDescription="Data Parsing Error" />
        </>
      );
    }

    if (isOfflineCatalogError(error)) {
      if (error.status === EMPTY_CATALOG_ERROR_CODE) {
        return (
          <>
            <EmptyCatalogErrorPage onButtonClick={() => navigate(RoutesConfig.mainPage)} />
            <DebuggerMenuController error={error} />
          </>
        );
      }
    }

    return (
      <>
        {this.props.children}
        {!error && <DebuggerMenuController />}
      </>
    );
  }
}
