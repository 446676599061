import { DefaultError, useMutation, useQueryClient } from "@tanstack/react-query";

import { createDraft } from "../api/createDraft";
import { LiteDraftRequest, LiteOrderRequisition } from "../typegens";
import { prependToOrderRequisitionsList } from "../utils";
import { useToastMessage } from "./useToastMessage";

export const LEGACY_useCreateOrderDraftMutation = (
  onCreateDraftSuccess?: (orderRequisition: LiteOrderRequisition) => void,
  onCreateDraftError?: () => void
) => {
  const { setToastMessage } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation<LiteOrderRequisition, DefaultError, LiteDraftRequest>({
    mutationKey: ["LEGACY_createDraft"],
    mutationFn: createDraft,
    onSuccess: (result) => {
      setToastMessage({
        type: "success",
        message: "Your draft was successfully saved",
      });
      if (onCreateDraftSuccess) {
        onCreateDraftSuccess(result);
      }
      queryClient.setQueryData(
        // Drafts are not closed orders, so this simplification is enough
        ["orderRequisitionsList", false],
        prependToOrderRequisitionsList([result])
      );
    },
    onError: () => {
      if (onCreateDraftError) {
        onCreateDraftError();
      }
      setToastMessage({
        type: "failure",
        message: "There was an error with saving a draft. Please try again.",
      });
    },
  });
};
