import { ComponentType, SVGProps } from "react";

import { OptionType } from "@web/ui";

import { LocalStocktakeType, LocalStocktakeTypeConfiguration } from "../../models";
import {
  BadgeSettings,
  getBaseBadgeSettingsForOrderAndStocktakeTypes,
  getIconFromOrderOrStocktakeType,
} from "./orderAndStocktakeTypesShared";

const universalStocktakeTypesConfiguration: LocalStocktakeTypeConfiguration[] = [
  {
    type: "BONDED",
    name: "Bonded",
  },
  {
    type: "PROVISIONS",
    name: "Provisions",
  },
  {
    type: "STORES",
    name: "Stores",
  },
  {
    type: "WELFARE",
    name: "Welfare",
  },
  {
    type: "GALLEY_CONSUMABLES",
    name: "Galley Consumables",
  },
  {
    type: "GALLEY_AND_LAUNDRY_EQUIPMENT",
    name: "Galley & Laundry Equipment",
  },
  {
    type: "DEFAULT",
    name: "Default",
  },
];

export const DEFAULT_STOCKTAKE_TYPE = "DEFAULT";

export const getDefaultedStocktakeTypeAccountingForConfigurationLogic = (
  availableStocktakeTypes: LocalStocktakeType[] = [],
  chosenStocktakeType: LocalStocktakeType | undefined
): LocalStocktakeType | undefined => {
  const shouldUseADefaultOrderType = availableStocktakeTypes.length <= 1;
  const isChosenStocktakeTypeValid =
    !!availableStocktakeTypes.find((st) => st === chosenStocktakeType) &&
    chosenStocktakeType !== null;

  // - Will return the only stocktake type if there's only one in the available types - it should be "DEFAULT", but we don't care in FE
  // - Will return undefined or chosen stocktake type if there are multiple stocktake types in the configuration,
  //   because in this scenario we want the user to choose one
  return shouldUseADefaultOrderType
    ? availableStocktakeTypes[0] ?? DEFAULT_STOCKTAKE_TYPE
    : isChosenStocktakeTypeValid
    ? chosenStocktakeType
    : undefined;
};

export const getStocktakeTypeLabel = (stocktakeType: LocalStocktakeType): string =>
  universalStocktakeTypesConfiguration.find((config) => config.type === stocktakeType)?.name ??
  "Unknown";

export const getStocktakeTypeIcon = getIconFromOrderOrStocktakeType;

export const shouldRenderStocktakeTypes = (
  availableStocktakeTypes: LocalStocktakeType[] = []
): boolean => {
  return availableStocktakeTypes.length > 1;
};

export const shouldRenderStocktakeType = (
  availableStocktakeTypes: LocalStocktakeType[] = [],
  stocktakeType?: LocalStocktakeType
): boolean => {
  return !!(availableStocktakeTypes.length > 1 && stocktakeType && stocktakeType !== "DEFAULT");
};

export const getBadgeSettingsFromStocktakeType = (
  stocktakeType: LocalStocktakeType
): BadgeSettings => {
  const baseConfig = getBaseBadgeSettingsForOrderAndStocktakeTypes(stocktakeType);
  return {
    ...baseConfig,
    text: getStocktakeTypeLabel(stocktakeType),
  };
};

export const getSelectOptionFromStocktakeType = (
  stocktakeType: LocalStocktakeType
): OptionType<LocalStocktakeType> => {
  return {
    value: stocktakeType,
    label: getStocktakeTypeLabel(stocktakeType),
    LeadingIcon: getStocktakeTypeIcon(stocktakeType),
  };
};

export const getStocktakeTypeDetails = (
  stocktakeType: LocalStocktakeType
): { Icon: ComponentType<SVGProps<SVGSVGElement>>; label: string } => ({
  Icon: getStocktakeTypeIcon(stocktakeType),
  label: getStocktakeTypeLabel(stocktakeType),
});
