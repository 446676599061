import classnames from "classnames";
import { ChangeEvent, FormEvent, useMemo } from "react";

import { getNonEmptyCategories } from "@web/common";
import { Category, Port } from "@web/models";
import { SearchBar, containerPadding } from "@web/ui";

import { CategorySelector as LiteCategorySelector } from "src/components/CategorySelector";
import { PortViewer } from "src/components/PortViewer";

type Props = {
  selectedPort?: Port;
  categorySelectorSettings?: {
    categories: Category[];
    onSelectToggle?(state: boolean): void;
    onCategoryChange?(category: Category): void;
    subCategoryRoute: string;
  };
  searchBarSettings?: {
    placeholder: string;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    onQueryChange: (e: ChangeEvent<HTMLInputElement>) => void;
    query: string;
    isOnline?: boolean;
    isOfflineSearchEnabled?: boolean;
  };
};

export const BrowseBar = ({ categorySelectorSettings, selectedPort, searchBarSettings }: Props) => {
  const categoriesWithProducts = useMemo(
    () => getNonEmptyCategories(categorySelectorSettings?.categories || []),
    [categorySelectorSettings?.categories]
  );
  const doesCategoriesMenuExist = categorySelectorSettings && categoriesWithProducts.length > 1;

  const hasOnlySearchBar = searchBarSettings && !selectedPort && !doesCategoriesMenuExist;
  const hasOnlySearchBarAndPort = searchBarSettings && selectedPort && !doesCategoriesMenuExist;

  return (
    <div className="bg-neutral_0 border-neutral_300 border-b border-b-neutral_200 w-full sticky z-10 top-0">
      <div className={containerPadding}>
        <div
          className={classnames("flex items-center content-center w-full bg-neutral_0 py-2 gap-5", {
            "justify-between": !hasOnlySearchBar,
            "justify-center": hasOnlySearchBar,
          })}
        >
          {doesCategoriesMenuExist && (
            <div className="flex-none flex gap-6 mr-3">
              <LiteCategorySelector {...categorySelectorSettings} />
            </div>
          )}
          {searchBarSettings && (
            <div
              className={classnames("flex-grow", {
                "max-w-[65%]": hasOnlySearchBar || hasOnlySearchBarAndPort,
                "ml-auto": hasOnlySearchBarAndPort,
              })}
            >
              <SearchBar {...searchBarSettings} removeOuterPaddings={true} />
            </div>
          )}
          {selectedPort && <PortViewer className="flex-none max-w-64" port={selectedPort} />}
        </div>
      </div>
    </div>
  );
};
