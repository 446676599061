import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate } from "react-router-dom";

import { OrderItem } from "@web/common";
import { SupplierInformation } from "@web/common/network";
import { Port } from "@web/models";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { BasketEntry } from "src/contexts/BasketContext";
import { LocalLiteOrderExtraItem, OrderStocktakeType } from "src/models";
import { LiteDutyFreeDeclaration, LiteProductSku, LiteRfqItem } from "src/typegens";
import { getItemUnits, pairProductsWithVariants } from "src/utils";

import useBasket from "./useBasket";

type AddProductsToBasketProps = {
  catalogItems: OrderItem[];
  extraItems: LocalLiteOrderExtraItem[];
  rfqItems: LiteRfqItem[];
  orderType: OrderStocktakeType;
  port: Port;
  supplierInformation: SupplierInformation;
  deliveryDate: string | undefined;
  dutyFreeDeclaration: LiteDutyFreeDeclaration | undefined;
  subject: string | undefined;
};

export const useAddProductsToBasket = ({
  catalogItems,
  port,
  deliveryDate,
  dutyFreeDeclaration,
  rfqItems,
  extraItems,
  orderType,
  subject,
  supplierInformation,
}: AddProductsToBasketProps) => {
  const navigate = useNavigate();
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const [, dispatch] = useAppStateContext();
  const {
    addMultipleCatalogItemsToBasket,
    addMultipleRfqsToBasket,
    setBasketExtraItems,
    clearBasket,
  } = useBasket();

  const addProductsToBasket = (liteProductList: LiteProductSku[]) => {
    const availableProducts: BasketEntry[] = (
      pairProductsWithVariants(catalogItems)(liteProductList).filter((e) => e[1]) as Array<
        [OrderItem, LiteProductSku]
      >
    ).map(([product, variant]) => ({
      sku: variant,
      quantity: getItemUnits({ product, variant }),
    }));

    if (hasSelectSupplierFeature) {
      dispatch({
        type: "setPreconfigureOrderSetup",
        value: {
          portId: port.id,
          deliveryDate,
          dutyFreeDeclaration,
          orderType,
          orderName: subject,
          supplierId: supplierInformation.id,
        },
      });
    } else {
      dispatch({
        type: "LEGACY_setPreconfigureOrderSetup",
        value: {
          port,
          deliveryDate,
          dutyFreeDeclaration,
          orderType,
          orderName: subject,
        },
      });
    }

    clearBasket();
    addMultipleCatalogItemsToBasket(availableProducts);
    if (rfqItems) {
      addMultipleRfqsToBasket(rfqItems);
    }
    if (extraItems.length > 0) {
      setBasketExtraItems(extraItems);
    }
    navigate(RoutesConfig.basket);
  };

  return { addProductsToBasket };
};
