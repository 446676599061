/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ApiListLiteProduct } from "../models/ApiListLiteProduct";
import type { LiteCategorySearchResult } from "../models/LiteCategorySearchResult";

export class CatalogSearchService {
  /**
   * @returns ApiListLiteProduct OK
   * @throws ApiError
   */
  public static searchProducts(
    {
      portId,
      query,
      sort,
      priceMin,
      priceMax,
      leadTime,
      brand,
      cursor,
      supplierId,
      orderType,
    }: {
      portId: string;
      query: string;
      sort?: "PRICE_ASC" | "PRICE_DESC";
      priceMin?: string;
      priceMax?: string;
      leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
      brand?: string;
      cursor?: string;
      supplierId?: string;
      orderType?:
        | "BONDED"
        | "PROVISIONS"
        | "STORES"
        | "WELFARE"
        | "GALLEY_AND_LAUNDRY_EQUIPMENT"
        | "GALLEY_CONSUMABLES"
        | "DEFAULT";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListLiteProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/search/products",
      query: {
        portId: portId,
        query: query,
        sort: sort,
        priceMin: priceMin,
        priceMax: priceMax,
        leadTime: leadTime,
        brand: brand,
        cursor: cursor,
        supplierId: supplierId,
        orderType: orderType,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteCategorySearchResult OK
   * @throws ApiError
   */
  public static searchCategories(
    {
      portId,
      query,
      supplierId,
      orderType,
    }: {
      portId: string;
      query: string;
      supplierId?: string;
      orderType?:
        | "BONDED"
        | "PROVISIONS"
        | "STORES"
        | "WELFARE"
        | "GALLEY_AND_LAUNDRY_EQUIPMENT"
        | "GALLEY_CONSUMABLES"
        | "DEFAULT";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<LiteCategorySearchResult>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/search/categories",
      query: {
        portId: portId,
        query: query,
        supplierId: supplierId,
        orderType: orderType,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
