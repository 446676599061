/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ApiListLiteOrderRequisition } from "../models/ApiListLiteOrderRequisition";
import type { ApiListLiteProductSku } from "../models/ApiListLiteProductSku";
import type { LiteOrderCreationRequest } from "../models/LiteOrderCreationRequest";
import type { LiteOrderRequisition } from "../models/LiteOrderRequisition";

export class OrdersService {
  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static closeOrder(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/orders/{orderId}/closures",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static cancelOrder(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/orders/{orderId}/cancellations",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static createOrder(
    {
      requestBody,
    }: {
      requestBody: LiteOrderCreationRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/orders",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListLiteProductSku OK
   * @throws ApiError
   */
  public static getAvailableProducts(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListLiteProductSku> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/orders/{orderId}/productVariants",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static getPurchaseOrderAsMtml(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/orders/{orderId}/mtml",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getPurchaseOrder(
    {
      orderId,
      outputFormat = "JSON",
    }: {
      orderId: string;
      outputFormat?: "JSON" | "XML";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/orders/{orderId}",
      path: {
        orderId: orderId,
      },
      query: {
        outputFormat: outputFormat,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static getOrderRequisitionById(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/orders/orderRequisition/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static exportOrderRequisitionByIdToExcel(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/orderRequisitions/{id}/excel",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListLiteOrderRequisition OK
   * @throws ApiError
   */
  public static getAllOrderRequisitions(
    {
      cursor = "",
      statuses,
      closed,
      pageSize = "25",
    }: {
      cursor?: string;
      statuses?: Array<
        | "CANCELLED"
        | "PENDING_CANCELLATION"
        | "SUPPLIER_ACKNOWLEDGED"
        | "SUPPLIER_CONFIRMED"
        | "SUPPLIER_PENDING"
        | "SUPPLIER_REJECTED"
        | "SUPPLIER_DELIVERED"
        | "PENDING_PURCHASER_APPROVAL"
        | "PURCHASER_REJECTED"
        | "SUPPLIER_QUOTE_PENDING"
        | "SUPPLIER_QUOTE_ACKNOWLEDGED"
        | "SUPPLIER_QUOTE_PROVIDED"
        | "SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED"
        | "QUOTE_EXPIRED"
        | "QUOTE_REJECTED"
        | "DRAFT_CREATED"
        | "DRAFT_DELETED"
      >;
      closed?: boolean;
      pageSize?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListLiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/orderRequisitions",
      query: {
        cursor: cursor,
        statuses: statuses,
        closed: closed,
        pageSize: pageSize,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
