import { z } from "zod";

export const SkuAttachmentTypeSchema = z.union([
  z.literal("APPROVAL"),
  z.literal("DATA_SHEET"),
  z.literal("DRAWING"),
  z.literal("CERTIFICATE"),
  z.literal("INSTRUCTIONS"),
  z.literal("NOT_DEFINED"),
]);
