import _isNull from "lodash/isNull";

import { getDefaultedOrderTypeAccountingForConfigurationLogic } from "@web/common";
import { Port } from "@web/models";
import { isDefined } from "@web/utils";

import { LocalConfigurationService } from "src/services/LocalConfigurationService";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";
import { LiteOrderTypeConfiguration, LitePort, LiteSupplierInformation } from "src/typegens";

import {
  AppState,
  AppStateAction,
  AttentionInfoItems,
  Configuration,
  LocalLitePort,
} from "./models";

export const defaultAppState: AppState = {
  systemMessages: [],
  vesselToken: undefined,
  isPunchoutSession: false,
  attentionInfo: [],
};

const getForcePreconfigureOrderSetupValue = ({
  orderType,
  port,
  supplier,
  hasSelectSupplierFeature,
}: {
  orderType: AppState["orderType"] | undefined;
  port: AppState["port"] | undefined;
  supplier: AppState["supplier"] | undefined;
  hasSelectSupplierFeature: boolean;
}): boolean => (hasSelectSupplierFeature ? !orderType || !port || !supplier : !port || !orderType);

const LEGACY_getOrderSetupValues = ({
  configuration,
  port,
  orderType,
}: {
  configuration: Configuration | undefined;
  port: Port | LitePort;
  orderType: LiteOrderTypeConfiguration["type"];
}): {
  calculatedOrderType: LiteOrderTypeConfiguration["type"] | undefined;
  calculatedPort: LocalLitePort | undefined;
  attentionInfo: AttentionInfoItems;
} => {
  const calculatedOrderType = getDefaultedOrderTypeAccountingForConfigurationLogic(
    configuration?.orderTypes,
    orderType
  );

  const calculatedPort = LocalConfigurationService.LEGACY_getConfiguredPort(configuration, port);

  return {
    calculatedOrderType: calculatedOrderType,
    calculatedPort: calculatedPort,
    attentionInfo: calculatedPort?.attentionInfo || [],
  };
};

const getOrderSetupValues = ({
  orderTypeConfigs,
  portId,
  orderType,
  supplierId,
}: {
  orderTypeConfigs: Configuration["orderTypeConfigs"] | undefined;
  portId: string;
  orderType: LiteOrderTypeConfiguration["type"];
  supplierId: string;
}): {
  calculatedOrderType: LiteOrderTypeConfiguration["type"] | undefined;
  calculatedPort: LocalLitePort | undefined;
  calculatedSupplier: LiteSupplierInformation | undefined;
  attentionInfo: AttentionInfoItems;
} => {
  const calculatedOrderType = getDefaultedOrderTypeAccountingForConfigurationLogic(
    orderTypeConfigs,
    orderType
  );

  const portConfig = LocalConfigurationService.getSelectedPortConfig({
    orderTypeConfigs,
    orderType: calculatedOrderType,
    portId,
  });
  // TODO #11962: After state model is narrowed down to just LitePort, remove attentionInfo default
  const calculatedPort = portConfig
    ? {
        id: portConfig.id,
        name: portConfig.name,
        country: portConfig.country,
        locationCode: portConfig.locationCode,
        attentionInfo: [],
      }
    : undefined;

  const supplierConfig = LocalConfigurationService.getSelectedSupplierConfig({
    orderTypeConfigs,
    orderType: calculatedOrderType,
    portId: calculatedPort?.id,
    supplierId,
  });
  const calculatedSupplier = supplierConfig
    ? {
        name: supplierConfig.name,
        id: supplierConfig.id,
      }
    : undefined;

  const isCombinationValid = !!calculatedOrderType && !!calculatedPort && !!calculatedSupplier;

  return {
    calculatedOrderType: isCombinationValid ? calculatedOrderType : undefined,
    calculatedPort: isCombinationValid ? calculatedPort : undefined,
    calculatedSupplier: isCombinationValid ? calculatedSupplier : undefined,
    attentionInfo: supplierConfig?.attentionInfo || [],
  };
};

export const appStateReducer = (state: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case "setAppState":
      return { ...state, ...action.value };

    case "setConfiguration": {
      return {
        ...state,
        configuration: {
          ...action.value,
          vessel: LocalOrderReqService.toVessel(action.value.vessel),
          canCreateOrder: LocalConfigurationService.canCreateOrder(action.value),
          canCreateRequisition: LocalConfigurationService.canCreateRequisition(action.value),
          isAutoApprovedRequisition: LocalConfigurationService.isAutoApprovedRequisition(
            action.value
          ),
          orderTypeConfigs:
            LocalConfigurationService.convertApiOrderTypeConfigsToLocalOrderTypeConfigs(
              action.value.orderTypeConfigs
            ),
          // TODO #11983: Remove deprecated fields some months after select supplier feature is released
          // @deprecated
          orderTypes: action.value.orderTypes ?? [],
          // @deprecated
          ports: LocalConfigurationService.LEGACY_toPorts(
            action.value.ports,
            action.value.portConfiguration,
            action.value.attentionInfo
          ),
        },
        // TODO #11983: Remove some months after select supplier feature is released
        // This tries to hydrate the supplier name if the `supplier` was set in the app state
        // prior to the configuration setup. This covers the edge case of a vessel
        // being in the middle of the gather experience when the select supplier feature
        // is released.
        ...(state.supplier?.id && !state.supplier?.name
          ? {
              supplier: {
                id: state.supplier.id,
                name:
                  action.value.orderTypeConfigs
                    .flatMap((orderType) => orderType.ports.flatMap((port) => port.suppliers))
                    .find((supplier) => supplier.id === state.supplier?.id)?.name || "",
              },
            }
          : {}),
      };
    }

    case "LEGACY_setPreconfigureOrderSetup": {
      const { port, deliveryDate, dutyFreeDeclaration, orderType, orderName } = action.value;
      const { calculatedOrderType, calculatedPort, attentionInfo } = LEGACY_getOrderSetupValues({
        configuration: state.configuration,
        orderType,
        port,
      });
      return {
        ...state,
        port: calculatedPort,
        deliveryDate,
        dutyFreeDeclaration:
          isDefined(dutyFreeDeclaration?.dutyFree) && !_isNull(dutyFreeDeclaration?.dutyFree)
            ? dutyFreeDeclaration
            : undefined,
        orderType: calculatedOrderType,
        orderName,
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: calculatedPort,
          orderType: calculatedOrderType,
          supplier: undefined,
          hasSelectSupplierFeature: false,
        }),
        supplier: undefined,
        lastGatherExpCategoryId: undefined,
        attentionInfo,
      };
    }

    case "setPreconfigureOrderSetup": {
      const { portId, deliveryDate, dutyFreeDeclaration, orderType, orderName, supplierId } =
        action.value;

      const { calculatedOrderType, calculatedPort, calculatedSupplier, attentionInfo } =
        getOrderSetupValues({
          orderTypeConfigs: state.configuration?.orderTypeConfigs,
          orderType,
          portId,
          supplierId,
        });

      return {
        ...state,
        orderType: calculatedOrderType,
        port: calculatedPort,
        supplier: calculatedSupplier,
        deliveryDate,
        dutyFreeDeclaration:
          isDefined(dutyFreeDeclaration?.dutyFree) && !_isNull(dutyFreeDeclaration?.dutyFree)
            ? dutyFreeDeclaration
            : undefined,
        orderName,
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: calculatedPort,
          orderType: calculatedOrderType,
          supplier: calculatedSupplier,
          hasSelectSupplierFeature: true,
        }),
        lastGatherExpCategoryId: undefined,
        attentionInfo,
      };
    }

    case "LEGACY_setNewOrderInReorderSetup": {
      const { orderType, port } = action.value;

      const { calculatedOrderType, calculatedPort, attentionInfo } = LEGACY_getOrderSetupValues({
        configuration: state.configuration,
        orderType,
        port,
      });
      return {
        ...state,
        orderType: calculatedOrderType,
        port: calculatedPort,
        supplier: undefined,
        forcePreconfigureOrderSetup: true,
        deliveryDate: undefined,
        dutyFreeDeclaration: undefined,
        orderName: undefined,
        lastGatherExpCategoryId: undefined,
        attentionInfo,
        isPunchoutSession: false,
      };
    }

    case "setNewOrderInReorderSetup": {
      const { orderType, portId, supplierId } = action.value;

      const { calculatedOrderType, calculatedPort, calculatedSupplier, attentionInfo } =
        getOrderSetupValues({
          orderTypeConfigs: state.configuration?.orderTypeConfigs,
          orderType,
          portId,
          supplierId,
        });

      return {
        ...state,
        orderType: calculatedOrderType,
        port: calculatedPort,
        supplier: calculatedSupplier,
        forcePreconfigureOrderSetup: false,
        deliveryDate: undefined,
        dutyFreeDeclaration: undefined,
        orderName: undefined,
        lastGatherExpCategoryId: undefined,
        attentionInfo,
        isPunchoutSession: false,
      };
    }

    case "LEGACY_setRepunchoutSetup": {
      const { orderType, port } = action.value;

      const { calculatedOrderType, calculatedPort, attentionInfo } = LEGACY_getOrderSetupValues({
        configuration: state.configuration,
        orderType,
        port,
      });
      return {
        ...state,
        orderType: calculatedOrderType,
        port: calculatedPort,
        supplier: undefined,
        forcePreconfigureOrderSetup: false,
        deliveryDate: undefined,
        dutyFreeDeclaration: undefined,
        orderName: undefined,
        lastGatherExpCategoryId: undefined,
        attentionInfo,
        isPunchoutSession: true,
      };
    }

    case "setRepunchoutSetup": {
      const { orderType, portId, supplierId } = action.value;

      const { calculatedOrderType, calculatedPort, calculatedSupplier, attentionInfo } =
        getOrderSetupValues({
          orderTypeConfigs: state.configuration?.orderTypeConfigs,
          orderType,
          portId,
          supplierId,
        });

      return {
        ...state,
        orderType: calculatedOrderType,
        port: calculatedPort,
        supplier: calculatedSupplier,
        forcePreconfigureOrderSetup: true,
        deliveryDate: undefined,
        dutyFreeDeclaration: undefined,
        orderName: undefined,
        lastGatherExpCategoryId: undefined,
        attentionInfo,
        isPunchoutSession: true,
      };
    }

    case "clearOrderFlow": {
      return {
        ...state,
        orderType: undefined,
        port: undefined,
        supplier: undefined,
        forcePreconfigureOrderSetup: true,
        deliveryDate: undefined,
        dutyFreeDeclaration: undefined,
        orderName: undefined,
        lastGatherExpCategoryId: undefined,
        attentionInfo: [],
        isPunchoutSession: false,
      };
    }

    case "setOrderName": {
      return {
        ...state,
        orderName: action.value,
      };
    }

    case "setSystemMessage":
      return {
        ...state,
        systemMessages: [...state.systemMessages, action.value],
      };

    case "clearSystemMessage":
      return {
        ...state,
        systemMessages: state.systemMessages.filter((msg) => msg.id !== action.value.id),
      };

    case "setVesselToken":
      return {
        ...state,
        vesselToken: action.value,
      };

    case "setLastCreated": {
      return {
        ...state,
        lastCreatedOrderRequisitions: (action.value ?? []).map((order) =>
          LocalOrderReqService.toOrderRequisition(order)
        ),
      };
    }

    case "setOfflineDraftEnabled": {
      return {
        ...state,
        isOfflineDraftEnabled: action.value,
      };
    }

    case "setAppArea": {
      return {
        ...state,
        appArea: action.value,
      };
    }

    case "setIsPunchoutSession": {
      return {
        ...state,
        isPunchoutSession: action.value,
      };
    }

    case "setLastGatherExpCategoryId": {
      return {
        ...state,
        lastGatherExpCategoryId: action.value,
      };
    }

    default:
      return state;
  }
};
