import { DefaultError, useMutation, useQueryClient } from "@tanstack/react-query";

import { updateDraft } from "src/api/updateDraft";
import useBasket from "src/hooks/useBasket";
import { LiteDraftRequest, LiteOrderRequisition } from "src/typegens";
import { updateOrderRequisitionsListItem } from "src/utils";

import { useOrderRequisitionQueryHelpers } from "./useOrderRequisitionQuery";
import { useToastMessage } from "./useToastMessage";

export const LEGACY_useUpdateOrderDraftMutation = (onUpdateDraftSuccess?: () => void) => {
  const { setDraft, draft } = useBasket();
  const { setToastMessage } = useToastMessage();
  const queryClient = useQueryClient();
  const { invalidate: invalidateOrderQuery } = useOrderRequisitionQueryHelpers();

  return useMutation<
    LiteOrderRequisition,
    DefaultError,
    {
      draftId: string;
      requestBody: LiteDraftRequest;
    }
  >({
    mutationKey: ["LEGACY_updateDraft"],
    mutationFn: updateDraft,
    onSuccess: async (result) => {
      // Do not update draft in state if it has been cleared in the meantime
      if (draft) {
        setDraft({ draftId: result.id, updatedAt: result.updatedAt });
      }

      queryClient.setQueryData(
        // Drafts are not closed orders, so this simplification is enough
        ["orderRequisitionsList", false],
        updateOrderRequisitionsListItem(result)
      );
      await invalidateOrderQuery(result.id);

      if (onUpdateDraftSuccess) {
        onUpdateDraftSuccess();
      }
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "There was an error with saving a draft",
      });
    },
  });
};
