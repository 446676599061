import { DefaultError, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { deleteDraft } from "src/api/deleteDraft";
import { RoutesConfig } from "src/config/routes";
import { deleteDraftFromOrderRequisitionsList } from "src/utils";

import { useOrderRequisitionQueryHelpers } from "./useOrderRequisitionQuery";
import { useToastMessage } from "./useToastMessage";

type Props = {
  onSuccess?: () => void;
  // This is a legacy hook, so we allow ourselves to leave this less-than-ideal fix
  shouldNavigate?: boolean;
};

export const LEGACY_useDeleteOrderDraftMutation = ({
  onSuccess,
  shouldNavigate = false,
}: Props = {}) => {
  const navigate = useNavigate();
  const { setToastMessage } = useToastMessage();
  const queryClient = useQueryClient();
  const { invalidate: invalidateOrderQuery } = useOrderRequisitionQueryHelpers();

  return useMutation<unknown, DefaultError, string>({
    mutationKey: ["LEGACY_deleteDraft"],
    mutationFn: deleteDraft,

    onSuccess: async (_, draftId) => {
      onSuccess && onSuccess();

      setToastMessage({
        type: "success",
        message: "You have successfully deleted your draft",
      });

      queryClient.setQueryData(
        // Drafts are not closed orders, so this simplification is enough
        ["orderRequisitionsList", false],
        deleteDraftFromOrderRequisitionsList(draftId)
      );
      await invalidateOrderQuery(draftId);

      shouldNavigate && navigate(RoutesConfig.order.overview);
    },
    onError: () => {
      setToastMessage({ type: "failure", message: "Your draft failed to delete" });
    },
  });
};
