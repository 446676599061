import { useFlag } from "@unleash/proxy-client-react";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import ReactGA from "react-ga4";

import { useSearchHelpers } from "@web/common/hooks";
import { flattenPorts, trackingCategories, trackingEvents } from "@web/common/utils";

import { BrowseBar } from "src/components/BrowseBar";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useCategories from "src/hooks/useCategories";
import { LiteCategory } from "src/typegens";

export const BasketHeader: React.FC = () => {
  const [{ configuration, port }] = useAppStateContext();
  const { isOnline } = useNetworkDetector();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const isOfflineSearchEnabled =
    useFlag("offline-products-search") && areOfflineCapabilitiesEnabled;
  const hasSelectSupplierFeature = useFlag("select-supplier");

  const { query, setQuery } = useSearchHelpers(RoutesConfig.search);
  const [typedQuery, setTypedQuery] = useState(query);

  const ports = useMemo(
    () => (configuration?.ports ? flattenPorts(configuration.ports) : []),
    [configuration?.ports]
  );
  const selectedPort = useMemo(
    () => (hasSelectSupplierFeature ? port : ports.find((p) => p.id === port?.id)),
    [hasSelectSupplierFeature, port, ports]
  );

  const handleQueryChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTypedQuery(e.target.value);
  }, []);

  const handleSearchSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (typedQuery.length > 0) {
        setQuery(typedQuery);
      }
    },
    [setQuery, typedQuery]
  );

  const setCategoryCallback = useCallback((category: LiteCategory) => {
    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.CATEGORY_TAB_CHANGED,
      label: category.name,
    });
  }, []);

  const onToggleCallback = useCallback((state: boolean) => {
    if (state === true) {
      ReactGA.event({
        category: trackingCategories.CREW_APP,
        action: trackingEvents.CATEGORY_DROPDOWN_ACTIVATED,
      });
    }
  }, []);

  const { categoriesQuery } = useCategories();

  if (!configuration) {
    return null;
  }

  return (
    <>
      <TopBarController />
      <BrowseBar
        selectedPort={selectedPort}
        categorySelectorSettings={{
          categories: categoriesQuery.data || [],
          onSelectToggle: onToggleCallback,
          onCategoryChange: setCategoryCallback,
          subCategoryRoute: RoutesConfig.category,
        }}
        searchBarSettings={{
          placeholder: "Search...",
          onSubmit: handleSearchSubmit,
          onQueryChange: handleQueryChange,
          query: typedQuery,
          isOnline: areOfflineCapabilitiesEnabled ? isOnline : true,
          isOfflineSearchEnabled,
        }}
      />
    </>
  );
};
